<template>
  <button
    class="atom-tooltip-text"
    type="button"
    :class="[
      `is-position-x-${positionX}`,
      `is-position-y-${positionY}`,
      { 'is-visible': isVisible },
    ]"

    @click="toggleTooltip"
  >
    <IonIcon
      :icon-name="icon"
      :icon-size="iconSize"
      :icon-color="iconColor"
    />

    <div class="atom-tooltip-text__text">
      {{ text }}
      <div class="atom-tooltip-text__triangle" />
    </div>
  </button>
</template>

<script setup>
const props = defineProps({
    /*
        Content
    */
    text: {
        type: String,
        default: '',
    },

    /*
        Layout
    */
    positionX: {
        type: String,
        default: 'center',
    },

    positionY: {
        type: String,
        default: 'top',
    },

    /*
        Styles
    */
    borderRadius: {
        type: String,
        default: 'var(--b-radius--medium)',
    },

    iconColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    textColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    backgroundColor: {
        type: String,
        default: 'var(--c-grey--light)',
    },

    icon: {
        type: String,
        default: 'information-circle',
    },

    iconSize: {
        type: String,
        default: '20px',
    },
});

const {
    backgroundColor,
    textColor,
    borderRadius,
} = toRefs(props);

/*
    Visibility
*/
const isVisible = ref(false);
const toggleTooltip = () => {
    isVisible.value = !isVisible.value;
};
</script>

<style lang="scss" scoped>

.atom-tooltip-text {
    position: relative;
    display: inline-block;
}

.atom-tooltip-text__text {
    @include fluid('width', 189px, 198px, 233px, false);
    @include z-index('tooltip');

    position: absolute;
    padding: 20px;
    border-radius: v-bind(borderRadius);
    background-color: v-bind(backgroundColor);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--description);
    line-height: var(--l-height--description);
    opacity: 0;
    transform-origin: top left;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    visibility: hidden;

    .is-visible & {
        opacity: 1;
        visibility: visible;
    }

    .is-position-y-top &{
        bottom: 40px;
    }

    .is-position-y-bottom &{
        top: 40px;
    }

    .is-position-x-left &{
        right: -18px;
    }

    .is-position-x-center &{
        left: 50%;
        transform: translateX(-50%);
    }

    .is-position-x-right &{
        left: -18px;
    }
}

.atom-tooltip-text__triangle {
    position: absolute;
    left: -1px;
    overflow: visible;
    width: 23px;
    height: 23px;
    border-radius: 5px;
    background-color: v-bind(backgroundColor);
    transform: rotate(45deg);

    .is-position-y-top &{
        bottom: -8px;
    }

    .is-position-y-bottom &{
        top: -8px;
    }

    .is-position-x-left &{
        right: 16px;
        left: unset;
    }

    .is-position-x-center &{
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
    }

    .is-position-x-right &{
        left: 16px;
    }

    &.position-y-bottom {
        bottom: -11px;
    }
}
</style>
