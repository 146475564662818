import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowBottom, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoBulbFilled, LazySvgoBulb, LazySvgoBurger, LazySvgoBus, LazySvgoCamera, LazySvgoCheckmarkCircle, LazySvgoChevronDown, LazySvgoChevronLeft, LazySvgoChevronRight, LazySvgoChevronUp, LazySvgoCloseSmall, LazySvgoClose, LazySvgoCookies, LazySvgoCrossmarkCircle, LazySvgoDate, LazySvgoDot, LazySvgoError, LazySvgoInformationCircle, LazySvgoInteraction, LazySvgoLogo, LazySvgoLogout, LazySvgoMinusCircle, LazySvgoMinus, LazySvgoNuevaLogo, LazySvgoParking, LazySvgoPlus, LazySvgoPopupError, LazySvgoPopupSuccess, LazySvgoPopupWarning, LazySvgoProfile, LazySvgoPromologicLogoClaim, LazySvgoPromologicLogo, LazySvgoQrCode, LazySvgoReveal, LazySvgoScroll, LazySvgoSearch, LazySvgoSettings, LazySvgoSuccess, LazySvgoTrain } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowBottom", LazySvgoArrowBottom],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoBulbFilled", LazySvgoBulbFilled],
["SvgoBulb", LazySvgoBulb],
["SvgoBurger", LazySvgoBurger],
["SvgoBus", LazySvgoBus],
["SvgoCamera", LazySvgoCamera],
["SvgoCheckmarkCircle", LazySvgoCheckmarkCircle],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoChevronLeft", LazySvgoChevronLeft],
["SvgoChevronRight", LazySvgoChevronRight],
["SvgoChevronUp", LazySvgoChevronUp],
["SvgoCloseSmall", LazySvgoCloseSmall],
["SvgoClose", LazySvgoClose],
["SvgoCookies", LazySvgoCookies],
["SvgoCrossmarkCircle", LazySvgoCrossmarkCircle],
["SvgoDate", LazySvgoDate],
["SvgoDot", LazySvgoDot],
["SvgoError", LazySvgoError],
["SvgoInformationCircle", LazySvgoInformationCircle],
["SvgoInteraction", LazySvgoInteraction],
["SvgoLogo", LazySvgoLogo],
["SvgoLogout", LazySvgoLogout],
["SvgoMinusCircle", LazySvgoMinusCircle],
["SvgoMinus", LazySvgoMinus],
["SvgoNuevaLogo", LazySvgoNuevaLogo],
["SvgoParking", LazySvgoParking],
["SvgoPlus", LazySvgoPlus],
["SvgoPopupError", LazySvgoPopupError],
["SvgoPopupSuccess", LazySvgoPopupSuccess],
["SvgoPopupWarning", LazySvgoPopupWarning],
["SvgoProfile", LazySvgoProfile],
["SvgoPromologicLogoClaim", LazySvgoPromologicLogoClaim],
["SvgoPromologicLogo", LazySvgoPromologicLogo],
["SvgoQrCode", LazySvgoQrCode],
["SvgoReveal", LazySvgoReveal],
["SvgoScroll", LazySvgoScroll],
["SvgoSearch", LazySvgoSearch],
["SvgoSettings", LazySvgoSettings],
["SvgoSuccess", LazySvgoSuccess],
["SvgoTrain", LazySvgoTrain],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
