<template>
  <div
    class="atom-button__wrapper"
    :class="[
      { 'has-addional-link': (link || hasLinkAction) && linkText },
      { 'is-inactive-tab': isInactiveTab },
      { 'has-text': text },
    ]"
  >
    <div class="atom-button__link-wrapper">
      <UtilRouteLink
        v-if="link && linkText"
        :link="link"
        class="atom-button__link"
      >
        {{ linkText }}
      </UtilRouteLink>

      <button
        v-if="hasLinkAction && linkText"
        type="button"
        :link="link"
        class="atom-button__link"
        @click="emit('on-link-action')"
      >
        {{ linkText }}
      </button>

      <AtomTooltipText
        v-if="(link || hasLinkAction) && linkTooltip"
        :text="linkTooltip"
        :position-x="linkTooltipPositionX"
        :position-y="linkTooltipPositionY"
      />
    </div>
    <component
      :is="htmlTag"
      class="atom-button"
      :type="buttonType"
      :disabled="isDisabled"
      :class="[
        `is-icon-${iconPosition}`,
        `is-size-${size}`,
        `has-icon-size-${iconSize}`,
        { 'has-icon': icon !== 'none' },
      ]"
      @click="onClick"
    >
      <span
        class="atom-button__text"
      >{{ text }}</span>

      <IonIcon
        v-if="icon !== 'none'"
        :icon-name="icon"
        class="atom-button__icon"
        :icon-color="textColor"
        :icon-size="iconSize"
      />
    </component>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
          Text
      */
    text: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    /*
          Styles
      */
    backgroundColor: {
        type: String,
        default: 'var(--c-button-bg--primary)',
    },

    textColor: {
        type: String,
        default: 'var(--c-button-text--primary)',
    },

    borderColor: {
        type: String,
        default: 'var(--c-button-border--primary)',
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--small)',
    },

    size: {
        type: String,
        default: 'default',
    },

    /*
          Icons
    */
    icon: {
        type: String,
        default: 'none',
    },

    iconSize: {
        type: String,
        default: '14px',
    },

    iconPosition: {
        type: String,
        default: 'right',
        validator: (value) => [
            'left',
            'right',
        ].includes(value),
    },

    /*
        Additional Link
    */
    link: {
        type: String,
        default: '',
    },

    linkText: {
        type: String,
        default: '',
    },

    linkTooltip: {
        type: String,
        default: '',
    },

    linkTooltipPositionX: {
        type: String,
        default: 'center',
        validator: (value) => [
            'left',
            'center',
            'right',
        ].includes(value),
    },

    linkTooltipPositionY: {
        type: String,
        default: 'top',
        validator: (value) => [
            'top',
            'bottom',
        ].includes(value),
    },

    /*
        States
    */
    isDisabled: {
        type: Boolean,
        default: false,
    },

    /*
        Functionality
    */
    callback: {
        type: Function,
        default: () => {},
    },

    htmlTag: {
        type: String,
        default: 'button',
        validator: (value) => [
            'button',
            'div',
        ].includes(value),
    },

    buttonType: {
        type: String,
        default: 'button',
        validator: (value) => [
            'button',
            'submit',
            'reset',
        ].includes(value),
    },

    hasLinkAction: {
        type: Boolean,
        default: false,
    },

    isInactiveTab: {
        type: Boolean,
        default: false,
    },

    alignment: {
        type: String,
        default: 'flex-end',
        validator: (value) => [
            'flex-start',
            'center',
            'flex-end',
        ].includes(value),
    },
});

const onClick = () => {
    if (props.isDisabled) return;
    props.callback();
    sendEvent({
        event: 'click',
        category: 'button',
        label: props.text,
    });
};

const {
    textColor,
    backgroundColor,
    borderColor,
    borderRadius,
    alignment,
} = toRefs(props);

const emit = defineEmits(['on-link-action']);
</script>

<style lang="scss" scoped>
.atom-button__wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: v-bind(alignment);
    text-align: center;

    &.has-addional-link {
        @include fluid('column-gap', 30px, 30px, 40px);
        row-gap: 15px;
    }
}

.atom-button__link-wrapper {
    display: inline-flex;
    align-items: center;
    column-gap: 10px;
}

.atom-button__link {
    color: v-bind(backgroundColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a--small);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--a--small);
    text-decoration: underline;
}
.atom-button__text {
    z-index: 1;
}

.atom-button__icon {
    z-index: 1;
    transition: transform 0.3s var(--ease-in-out--sine);
}

.atom-button {
    &.is-size-default {
        @include fluid('padding-top', 15px, 15px, 18px);
        @include fluid('padding-bottom', 15px, 15px, 18px);
        @include fluid('padding-left', 25px, 25px, 35px);
        @include fluid('padding-right', 25px, 25px, 35px);
    }

    &.is-size-small {
        padding: 13px 20px;

        &.has-icon {
            padding: 13.4px 20px;
        }

        &.has-icon-size-20px {
            // padding: 0;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    position: relative;
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    align-self: flex-start;
    border: none;
    border: 2px solid v-bind(borderColor);
    border-radius: v-bind(borderRadius);
    -webkit-appearance: none;
    background-color: v-bind(backgroundColor);
    color: v-bind(textColor);
    column-gap: 12px;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--button);
    font-weight: var(--f-weight--700);
    line-height: var(--l-height--button);
    outline: none;
    transition: opacity 0.3s var(--ease-in-out--sine);

    .is-inactive-tab & {
        border-color: var(--c-grey--light);
        background-color: var(--c-grey--light);
        color: var(--c-primary);
    }

    /*
        Icon
    */
    &.is-icon-left {
        .atom-button__icon {
            order: -1;
        }
    }

    /*
        States
    */
    &:after {
        position: absolute;
        top: 0;
        left: -5px;
        width: 100%;
        height: 100%;
        background: var(--c-primary--light);
        content: '';
        transform: translateX(-100%);
        transition: transform 0.6s var(--ease-in-out--quint);
    }

    &:disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}
</style>
