<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="atom-richtext"
    :class="{
      'is-on-grid': isOnGrid,
      'is-light': isLight,
    }"
  >
    <div
      class="atom-richtext__inner"
      v-html="html"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    html: {
        type: String,
        required: true,
        default: '',
        validator: (value) => value.length > 0,
    },

    textColorLight: {
        type: String,
        default: 'var(--c-richtext--light)',
    },

    textColorDark: {
        type: String,
        default: 'var(--c-richtext--dark)',
    },

    isLight: {
        type: Boolean,
        default: false,
    },

    alignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    isOnGrid: {
        type: Boolean,
        default: false,
    },
});

const {
    textColorDark,
    textColorLight,
    alignment,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.atom-richtext{
    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        margin-bottom: var(--m-bottom--medium);
    }
}

.atom-richtext__inner {
    --font-color: v-bind(textColorDark);
    color: var(--font-color);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--p);
    text-align: v-bind(alignment);

    .is-light & {
        --font-color: v-bind(textColorLight);
    }

    .is-on-grid & {
        @include default-content-columns('text');
    }

    ::v-deep(ul)  {
        @include fluid-simple('margin-bottom', 16px, 19px);
        display: inline-block;
        padding-left: 25px;

        li {
            position: relative;

            &:before {
                position: absolute;
                top: 14px;
                left: -15px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: var(--font-color);
                content: '';
            }
        }
    }

    ::v-deep(strong) {
        font-weight: var(--f-weight--900);
    }

    ::v-deep(b) {
        font-weight: var(--f-weight--900);
    }

    ::v-deep(p)  {
        margin: var(--f-size--p) 0;

        b, strong {
            font-family: var(--f-family--primary);
        }

        &:last-child {
            margin: 0;
        }
    }

    ::v-deep(a) {
        color: var(--c-primary);
        font-weight: var(--f-weight--primary);
        text-decoration: underline;
    }
}
</style>
