import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as current_45winsmewY7aDFaQMeta } from "/vercel/path0/pages/current-wins.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as data_45policyIUc6zlSmpZMeta } from "/vercel/path0/pages/data-policy.vue?macro=true";
import { default as magic_45linkycroX4AXi6Meta } from "/vercel/path0/pages/handlers/magic-link.vue?macro=true";
import { default as imprintiuNFFLYTouMeta } from "/vercel/path0/pages/imprint.vue?macro=true";
import { default as login_45magic_45expiredWY7ODTmZoWMeta } from "/vercel/path0/pages/login-magic-expired.vue?macro=true";
import { default as login_45magic4edmGAfHrfMeta } from "/vercel/path0/pages/login-magic.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as password_45forgotsDu2RUd02MMeta } from "/vercel/path0/pages/password-forgot.vue?macro=true";
import { default as password_45reset6pLgh9teUjMeta } from "/vercel/path0/pages/password-reset.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug___fr",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: current_45winsmewY7aDFaQMeta?.name ?? "current-wins___de",
    path: current_45winsmewY7aDFaQMeta?.path ?? "/current-wins",
    meta: current_45winsmewY7aDFaQMeta || {},
    alias: current_45winsmewY7aDFaQMeta?.alias || [],
    redirect: current_45winsmewY7aDFaQMeta?.redirect,
    component: () => import("/vercel/path0/pages/current-wins.vue").then(m => m.default || m)
  },
  {
    name: current_45winsmewY7aDFaQMeta?.name ?? "current-wins___fr",
    path: current_45winsmewY7aDFaQMeta?.path ?? "/fr/current-wins",
    meta: current_45winsmewY7aDFaQMeta || {},
    alias: current_45winsmewY7aDFaQMeta?.alias || [],
    redirect: current_45winsmewY7aDFaQMeta?.redirect,
    component: () => import("/vercel/path0/pages/current-wins.vue").then(m => m.default || m)
  },
  {
    name: current_45winsmewY7aDFaQMeta?.name ?? "current-wins___en",
    path: current_45winsmewY7aDFaQMeta?.path ?? "/en/current-wins",
    meta: current_45winsmewY7aDFaQMeta || {},
    alias: current_45winsmewY7aDFaQMeta?.alias || [],
    redirect: current_45winsmewY7aDFaQMeta?.redirect,
    component: () => import("/vercel/path0/pages/current-wins.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard___de",
    path: dashboardHdtnClvoGAMeta?.path ?? "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard___fr",
    path: dashboardHdtnClvoGAMeta?.path ?? "/fr/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard___en",
    path: dashboardHdtnClvoGAMeta?.path ?? "/en/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: data_45policyIUc6zlSmpZMeta?.name ?? "data-policy___de",
    path: data_45policyIUc6zlSmpZMeta?.path ?? "/data-policy",
    meta: data_45policyIUc6zlSmpZMeta || {},
    alias: data_45policyIUc6zlSmpZMeta?.alias || [],
    redirect: data_45policyIUc6zlSmpZMeta?.redirect,
    component: () => import("/vercel/path0/pages/data-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45policyIUc6zlSmpZMeta?.name ?? "data-policy___fr",
    path: data_45policyIUc6zlSmpZMeta?.path ?? "/fr/data-policy",
    meta: data_45policyIUc6zlSmpZMeta || {},
    alias: data_45policyIUc6zlSmpZMeta?.alias || [],
    redirect: data_45policyIUc6zlSmpZMeta?.redirect,
    component: () => import("/vercel/path0/pages/data-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45policyIUc6zlSmpZMeta?.name ?? "data-policy___en",
    path: data_45policyIUc6zlSmpZMeta?.path ?? "/en/data-policy",
    meta: data_45policyIUc6zlSmpZMeta || {},
    alias: data_45policyIUc6zlSmpZMeta?.alias || [],
    redirect: data_45policyIUc6zlSmpZMeta?.redirect,
    component: () => import("/vercel/path0/pages/data-policy.vue").then(m => m.default || m)
  },
  {
    name: magic_45linkycroX4AXi6Meta?.name ?? "handlers-magic-link___de",
    path: magic_45linkycroX4AXi6Meta?.path ?? "/handlers/magic-link",
    meta: magic_45linkycroX4AXi6Meta || {},
    alias: magic_45linkycroX4AXi6Meta?.alias || [],
    redirect: magic_45linkycroX4AXi6Meta?.redirect,
    component: () => import("/vercel/path0/pages/handlers/magic-link.vue").then(m => m.default || m)
  },
  {
    name: magic_45linkycroX4AXi6Meta?.name ?? "handlers-magic-link___fr",
    path: magic_45linkycroX4AXi6Meta?.path ?? "/fr/handlers/magic-link",
    meta: magic_45linkycroX4AXi6Meta || {},
    alias: magic_45linkycroX4AXi6Meta?.alias || [],
    redirect: magic_45linkycroX4AXi6Meta?.redirect,
    component: () => import("/vercel/path0/pages/handlers/magic-link.vue").then(m => m.default || m)
  },
  {
    name: magic_45linkycroX4AXi6Meta?.name ?? "handlers-magic-link___en",
    path: magic_45linkycroX4AXi6Meta?.path ?? "/en/handlers/magic-link",
    meta: magic_45linkycroX4AXi6Meta || {},
    alias: magic_45linkycroX4AXi6Meta?.alias || [],
    redirect: magic_45linkycroX4AXi6Meta?.redirect,
    component: () => import("/vercel/path0/pages/handlers/magic-link.vue").then(m => m.default || m)
  },
  {
    name: imprintiuNFFLYTouMeta?.name ?? "imprint___de",
    path: imprintiuNFFLYTouMeta?.path ?? "/imprint",
    meta: imprintiuNFFLYTouMeta || {},
    alias: imprintiuNFFLYTouMeta?.alias || [],
    redirect: imprintiuNFFLYTouMeta?.redirect,
    component: () => import("/vercel/path0/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprintiuNFFLYTouMeta?.name ?? "imprint___fr",
    path: imprintiuNFFLYTouMeta?.path ?? "/fr/imprint",
    meta: imprintiuNFFLYTouMeta || {},
    alias: imprintiuNFFLYTouMeta?.alias || [],
    redirect: imprintiuNFFLYTouMeta?.redirect,
    component: () => import("/vercel/path0/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprintiuNFFLYTouMeta?.name ?? "imprint___en",
    path: imprintiuNFFLYTouMeta?.path ?? "/en/imprint",
    meta: imprintiuNFFLYTouMeta || {},
    alias: imprintiuNFFLYTouMeta?.alias || [],
    redirect: imprintiuNFFLYTouMeta?.redirect,
    component: () => import("/vercel/path0/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: login_45magic_45expiredWY7ODTmZoWMeta?.name ?? "login-magic-expired___de",
    path: login_45magic_45expiredWY7ODTmZoWMeta?.path ?? "/login-magic-expired",
    meta: login_45magic_45expiredWY7ODTmZoWMeta || {},
    alias: login_45magic_45expiredWY7ODTmZoWMeta?.alias || [],
    redirect: login_45magic_45expiredWY7ODTmZoWMeta?.redirect,
    component: () => import("/vercel/path0/pages/login-magic-expired.vue").then(m => m.default || m)
  },
  {
    name: login_45magic_45expiredWY7ODTmZoWMeta?.name ?? "login-magic-expired___fr",
    path: login_45magic_45expiredWY7ODTmZoWMeta?.path ?? "/fr/login-magic-expired",
    meta: login_45magic_45expiredWY7ODTmZoWMeta || {},
    alias: login_45magic_45expiredWY7ODTmZoWMeta?.alias || [],
    redirect: login_45magic_45expiredWY7ODTmZoWMeta?.redirect,
    component: () => import("/vercel/path0/pages/login-magic-expired.vue").then(m => m.default || m)
  },
  {
    name: login_45magic_45expiredWY7ODTmZoWMeta?.name ?? "login-magic-expired___en",
    path: login_45magic_45expiredWY7ODTmZoWMeta?.path ?? "/en/login-magic-expired",
    meta: login_45magic_45expiredWY7ODTmZoWMeta || {},
    alias: login_45magic_45expiredWY7ODTmZoWMeta?.alias || [],
    redirect: login_45magic_45expiredWY7ODTmZoWMeta?.redirect,
    component: () => import("/vercel/path0/pages/login-magic-expired.vue").then(m => m.default || m)
  },
  {
    name: login_45magic4edmGAfHrfMeta?.name ?? "login-magic___de",
    path: login_45magic4edmGAfHrfMeta?.path ?? "/login-magic",
    meta: login_45magic4edmGAfHrfMeta || {},
    alias: login_45magic4edmGAfHrfMeta?.alias || [],
    redirect: login_45magic4edmGAfHrfMeta?.redirect,
    component: () => import("/vercel/path0/pages/login-magic.vue").then(m => m.default || m)
  },
  {
    name: login_45magic4edmGAfHrfMeta?.name ?? "login-magic___fr",
    path: login_45magic4edmGAfHrfMeta?.path ?? "/fr/login-magic",
    meta: login_45magic4edmGAfHrfMeta || {},
    alias: login_45magic4edmGAfHrfMeta?.alias || [],
    redirect: login_45magic4edmGAfHrfMeta?.redirect,
    component: () => import("/vercel/path0/pages/login-magic.vue").then(m => m.default || m)
  },
  {
    name: login_45magic4edmGAfHrfMeta?.name ?? "login-magic___en",
    path: login_45magic4edmGAfHrfMeta?.path ?? "/en/login-magic",
    meta: login_45magic4edmGAfHrfMeta || {},
    alias: login_45magic4edmGAfHrfMeta?.alias || [],
    redirect: login_45magic4edmGAfHrfMeta?.redirect,
    component: () => import("/vercel/path0/pages/login-magic.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___de",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___fr",
    path: logint0AWlhQgM0Meta?.path ?? "/fr/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___en",
    path: logint0AWlhQgM0Meta?.path ?? "/en/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: password_45forgotsDu2RUd02MMeta?.name ?? "password-forgot___de",
    path: password_45forgotsDu2RUd02MMeta?.path ?? "/password-forgot",
    meta: password_45forgotsDu2RUd02MMeta || {},
    alias: password_45forgotsDu2RUd02MMeta?.alias || [],
    redirect: password_45forgotsDu2RUd02MMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-forgot.vue").then(m => m.default || m)
  },
  {
    name: password_45forgotsDu2RUd02MMeta?.name ?? "password-forgot___fr",
    path: password_45forgotsDu2RUd02MMeta?.path ?? "/fr/password-forgot",
    meta: password_45forgotsDu2RUd02MMeta || {},
    alias: password_45forgotsDu2RUd02MMeta?.alias || [],
    redirect: password_45forgotsDu2RUd02MMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-forgot.vue").then(m => m.default || m)
  },
  {
    name: password_45forgotsDu2RUd02MMeta?.name ?? "password-forgot___en",
    path: password_45forgotsDu2RUd02MMeta?.path ?? "/en/password-forgot",
    meta: password_45forgotsDu2RUd02MMeta || {},
    alias: password_45forgotsDu2RUd02MMeta?.alias || [],
    redirect: password_45forgotsDu2RUd02MMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-forgot.vue").then(m => m.default || m)
  },
  {
    name: password_45reset6pLgh9teUjMeta?.name ?? "password-reset___de",
    path: password_45reset6pLgh9teUjMeta?.path ?? "/password-reset",
    meta: password_45reset6pLgh9teUjMeta || {},
    alias: password_45reset6pLgh9teUjMeta?.alias || [],
    redirect: password_45reset6pLgh9teUjMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45reset6pLgh9teUjMeta?.name ?? "password-reset___fr",
    path: password_45reset6pLgh9teUjMeta?.path ?? "/fr/password-reset",
    meta: password_45reset6pLgh9teUjMeta || {},
    alias: password_45reset6pLgh9teUjMeta?.alias || [],
    redirect: password_45reset6pLgh9teUjMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45reset6pLgh9teUjMeta?.name ?? "password-reset___en",
    path: password_45reset6pLgh9teUjMeta?.path ?? "/en/password-reset",
    meta: password_45reset6pLgh9teUjMeta || {},
    alias: password_45reset6pLgh9teUjMeta?.alias || [],
    redirect: password_45reset6pLgh9teUjMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register___de",
    path: registerIikvtlfpbHMeta?.path ?? "/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register___fr",
    path: registerIikvtlfpbHMeta?.path ?? "/fr/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register___en",
    path: registerIikvtlfpbHMeta?.path ?? "/en/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.name ?? undefined,
    path: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.path ?? "/register",
    meta: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta || {},
    alias: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.alias || [],
    redirect: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.redirect,
    component: () => import("/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.name ?? undefined,
    path: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.path ?? "/play-codes",
    meta: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta || {},
    alias: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.alias || [],
    redirect: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.redirect,
    component: () => import("/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.name ?? undefined,
    path: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.path ?? "/free-code",
    meta: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta || {},
    alias: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.alias || [],
    redirect: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.redirect,
    component: () => import("/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.name ?? undefined,
    path: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.path ?? "/recommend",
    meta: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta || {},
    alias: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.alias || [],
    redirect: nuxt_643_4610_461__64types_43node_6418_4619_4614_eslint_648_4656_460_rollup_644_4613_460_sass_641_4670_460_stylelint_6414_4616tW5nj7r18aMeta?.redirect,
    component: () => import("/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]