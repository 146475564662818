import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45fetch_45settings_45global from "/vercel/path0/middleware/01.fetch.settings.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45fetch_45settings_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-auth": () => import("/vercel/path0/middleware/auth.auth.js"),
  "auth-guest": () => import("/vercel/path0/middleware/auth.guest.js"),
  "auth-resetquery": () => import("/vercel/path0/middleware/auth.resetquery.js"),
  "page-permission": () => import("/vercel/path0/middleware/page.permission.js"),
  "play-hascurrentwins": () => import("/vercel/path0/middleware/play.hascurrentwins.js")
}