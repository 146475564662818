import revive_payload_client_BLhXgKZkKj from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_O9UykPLfQ4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uU7LFwlsri from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_2fYAnDugET from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Qzm6HwaV08 from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_FQedxZzJgS from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.13.0_typescript@4.9.5_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_C4xQjpLAzA from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hDBOp8lXbp from "/vercel/path0/node_modules/.pnpm/nuxt-lenis@1.0.4_rollup@4.13.0/node_modules/nuxt-lenis/dist/runtime/plugin.mjs";
import i18n_EA6sb9iteO from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.2_rollup@4.13.0_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_mokluXF0HS from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.1_@types+node@18.19.14_eslint@8.56.0_rollup@4.13.0_sass@1.70.0_stylelint@14.16.1_ty_mhc4bqq2yvox7vbsgnq2szlqem/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import app_init_hybrid_5gOhL5QG1b from "/vercel/path0/plugins/app-init-hybrid.js";
import app_init_client_lDwNIL1JCF from "/vercel/path0/plugins/app-init.client.js";
import lenis_scrollto_top_jSiQdGbAmW from "/vercel/path0/plugins/lenis-scrollto-top.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import vue_gtag_S6KjvYXIWb from "/vercel/path0/plugins/vue-gtag.js";
export default [
  revive_payload_client_BLhXgKZkKj,
  unhead_O9UykPLfQ4,
  router_uU7LFwlsri,
  payload_client_2fYAnDugET,
  check_outdated_build_client_Qzm6HwaV08,
  plugin_vue3_FQedxZzJgS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_C4xQjpLAzA,
  plugin_hDBOp8lXbp,
  i18n_EA6sb9iteO,
  chunk_reload_client_mokluXF0HS,
  app_init_hybrid_5gOhL5QG1b,
  app_init_client_lDwNIL1JCF,
  lenis_scrollto_top_jSiQdGbAmW,
  sentry_client_GoGQuZo4Et,
  vue_gtag_S6KjvYXIWb
]