<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="html"
    class="atom-heading"
    :class="[
      `is-size-${fontSize}`,
      { 'has-hyphens': allowHyphens },
      { 'is-on-grid': isOnGrid },
    ]"
  >
    <div v-if="pretitle" class="atom-heading__pretitle">
      {{ pretitle }}
    </div>

    <h1 v-if="htmlTag === 'h1'" v-html="html" />
    <h2 v-else-if="htmlTag === 'h2'" v-html="html" />
    <h3 v-else-if="htmlTag === 'h3'" v-html="html" />
    <h4 v-else-if="htmlTag === 'h4'" v-html="html" />
    <h5 v-else-if="htmlTag === 'h5'" v-html="html" />
    <h6 v-else-if="htmlTag === 'h6'" v-html="html" />
    <div v-else class="atom-heading">
      {{ html }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    htmlTag: {
        type: String,
        default: 'h2',
        validator: (value) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'p', 'span'].includes(value),
    },

    fontSize: {
        type: String,
        default: 'h2',
        validator: (value) => ['h1', 'h2', 'h3', 'h4', 'h5'].includes(value),
    },

    fontWeight: {
        type: String,
        default: 'var(--f-weight--900)',
    },

    fontColorh1: {
        type: String,
        default: 'var(--c-heading--h1)',
    },

    fontColorh2: {
        type: String,
        default: 'var(--c-heading--h2)',
    },

    fontColorh3: {
        type: String,
        default: 'var(--c-heading--h3)',
    },

    fontColorh4: {
        type: String,
        default: 'var(--c-heading--h4)',
    },

    fontColorh5: {
        type: String,
        default: 'var(--c-heading--h5)',
    },

    alignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    text: {
        type: String,
        default: '',
    },

    pretitle: {
        type: String,
        default: '',
    },

    /*
      Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    allowHyphens: {
        type: Boolean,
        default: false,
    },
});

const {
    fontColorh1,
    fontSize,
    fontWeight,
    fontColorh2,
    fontColorh3,
    fontColorh4,
    fontColorh5,
    alignment,
    text,
} = toRefs(props);

const fontColor = computed(() => {
    switch (fontSize.value) {
    case 'h1':
        return fontColorh1.value;
    case 'h2':
        return fontColorh2.value;
    case 'h3':
        return fontColorh3.value;
    case 'h4':
        return fontColorh4.value;
    case 'h5':
        return fontColorh5.value;
    default:
        return fontColorh2.value;
    }
});

/*
  Replacements with Handlebars
*/
const html = ref('');

watchEffect(() => {
    html.value = compileWithHandlebars(text.value, user.value);
});
</script>

<style lang="scss" scoped>
.atom-heading {

    /*
        Color schema
    */
    --font-color: v-bind(fontColor);
    color: var(--font-color);
    font-family: var(--f-family--primary);
    font-weight: v-bind(fontWeight);
    text-align: v-bind(alignment);

    strong {
        font-weight: var(--f-weight--900);
    }

    &.has-hyphens {
        width: 100%;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        overflow-wrap: break-word;
        word-wrap: break-word;

        @include tablet-portrait {
            width: auto;
            hyphens: none;
        }
    }

    /*
        Font size
    */
    &.is-size-h1 {
        font-size: var(--f-size--h1);
        font-weight: var(--f-weight--900);
        line-height: var(--l-height--h1);
    }

    &.is-size-h2 {
        font-size: var(--f-size--h2);
        font-weight: var(--f-weight--900);
        line-height: var(--l-height--h2);
    }

    &.is-size-h3 {
        font-size: var(--f-size--h3);
        font-weight: var(--f-weight--900);
        line-height: var(--l-height--h3);
    }

    &.is-size-h4 {
        font-size: var(--f-size--h4);
        font-weight: v-bind(fontWeight);
        line-height: var(--l-height--h4);
    }

    &.is-size-h5 {
        font-size: var(--f-size--h5);
        font-weight: var(--f-weight--500);
        line-height: var(--l-height--h5);
    }

    &.is-size-h6 {
        font-size: var(--f-size--h6);
        line-height: var(--l-height--h6);
    }

    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        margin-bottom: var(--m-bottom--small);
        row-gap: 0;

        & > * {
            @include default-content-columns('text');
        }
    }

    ::v-deep(.is-light) {
        font-weight: var(--f-weight--500);
    }
}

.atom-heading__pretitle {
    color: var(--font-color);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--p);
}
</style>
