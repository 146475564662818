export const isAuthenticated = ref(false);
export const user = ref({});
export const userProfile = ref(null);

export const setUserProfile = (data = {}) => {
    user.value = {
        ...user.value,
        ...data,
    };
};

/*
    Set reactive user and isAuthenticated to true
*/
export const setUser = () => {
    const userToSet = useCookie('user');

    if (userToSet.value) {
        user.value = {
            ...user.value,
            ...userToSet.value,
        };

        isAuthenticated.value = true;
        return;
    }

    user.value = null;
    isAuthenticated.value = false;
};

/*
    Remove reactive user and set isAuthenticated to false
*/
export const removeUser = () => {
    user.value = null;
    isAuthenticated.value = false;
};

export const useUserMe = async () => {
    const { error: e, data } = await $fetch('/api/auth/me', {
        method: 'post',
    });

    if (e) {
        useSentryError(e, {
            useThrow: true,
            payload: null,
            response: data,
        });
    }

    setUserProfile(data);
};

/*
    Login
    @param {object} body - the login body
    @param {string} redirectUrl - the url to redirect to after login
*/
export const useLogin = async (body) => {
    const { error: e, data } = await $fetch('/api/auth/login', {
        method: 'post',
        body,
    });

    if (data?.notConfirmed || data?.wrongCredentials || data?.blocked || e) {
        return {
            data,
            error: e,
        };
    }

    if (e) {
        useSentryError(e, {
            useThrow: true,
            payload: body,
            response: data,
        });
    }

    setUser();

    const wincodeCookie = useCookie('wincode');
    const redirectUrl = wincodeCookie.value ? '/play-codes' : '/dashboard';

    if (redirectUrl) {
        const localePath = useLocalePath();
        await navigateTo(localePath(redirectUrl));
    }

    return {};
};

/*
    Update
*/
export const useUpdateUser = async (body) => {
    const { error: e, data } = await $fetch('/api/auth/update', {
        method: 'post',
        body,
    });

    if (e) {
        useSentryError(e, {
            useThrow: true,
            payload: body,
            response: data,
        });
    }

    useUserMe();
};

/*
    Delete
*/
export const useDeleteUser = async (redirectUrl) => {
    const { error: e, data } = await $fetch('/api/auth/delete', {
        method: 'post',
    });

    if (e) {
        useSentryError(e, {
            useThrow: true,
            payload: null,
            response: data,
        });
    }

    removeUser();

    if (redirectUrl) {
        const localePath = useLocalePath();
        navigateTo(localePath(redirectUrl));
    }
};

/*
    Logout
    @param {string} redirectUrl - the url to redirect to after logout
*/
export const useLogout = async (redirectUrl) => {
    await $fetch('/api/auth/logout', {
        method: 'post',
    });

    removeUser();

    if (redirectUrl) {
        const localePath = useLocalePath();
        navigateTo(localePath(redirectUrl));
    }
};
