// eslint-disable-next-line import/no-extraneous-dependencies
import { createGtm } from '@gtm-support/vue-gtm';

const trackingAllowed = ref(false);
const debug = true;
const clientID = ref(null);
let $gtm = null;

/*
    Settings
*/

/*
    Helper function to check if tracking is allowed
*/
const _doNotTrack = () => {
    const { public: publicConfig } = useRuntimeConfig();

    return trackingAllowed.value === false || publicConfig.USE_GTM === 'false';
};

/*
    Helper function to enable gtm
*/
const _enableGTM = () => {
    if ($gtm && !$gtm.enabled()) {
        $gtm.enable(true);
        clientID.value = useCookie('_ga')?.value;

        if (debug) {
            $gtm.debug(true);
        }
    }
};

/*
    Helper function to send gtm events
*/
export const sendEvent = (options) => {
    if (_doNotTrack()) return;
    if (Object.keys(options).includes('ecommerce')) {
        $gtm.trackEvent({
            ecommerce: null,
        });
    }
    $gtm.trackEvent(options);
};

/*
    Helper function to get client id
*/
export const getClientID = () => clientID.value;

/*
    Helper fuction to send page views
*/
export const sendPageView = async () => {
    if (_doNotTrack()) return;
    const route = useRoute();
    await sleep(300);
    $gtm.trackView(document.title, route.path);
};

export const initGtm = () => {
    /*
        Init GTM
    */
    const nuxtApp = useNuxtApp();
    const { public: publicRuntimeConfig } = useRuntimeConfig();
    const id = publicRuntimeConfig.GTM_ID;
    trackingAllowed.value = true;

    if (!id || publicRuntimeConfig.USE_GTM === 'false' || publicRuntimeConfig.IS_STORYBOOK) return;

    /*
        Assign gtm to vue app
    */
    nuxtApp.vueApp.use(createGtm({
        id: [id, 'GTM-KQDKVWL'],
        enabled: false,
        defer: true,
        debug: false,
    }));

    /*
        Set reference of vue gtm to $gtm variable for easier usage
    */
    $gtm = nuxtApp.vueApp.config.globalProperties.$gtm;

    /*
        Enable gtm and send initial page view
    */
    _enableGTM();
    sendPageView();

    /*
        Watch route changes and send page views
    */
    const route = useRoute();
    const isSentScrollEvent = ref(false);
    watch(() => route.path, (newValue) => {
        sendPageView(newValue);
        isSentScrollEvent.value = false;
    });
};
