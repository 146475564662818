export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"X-UA-Compatible","content":"IE=edge"},{"name":"revisit-after","content":"7"},{"name":"HandheldFriendly","content":"true"},{"name":"MobileOptimized","content":"320"},{"name":"medium","content":"mult"},{"name":"coverage","content":"wordlwide"},{"name":"distribution","content":"Global"},{"name":"rating","content":"General"},{"name":"target","content":"all"},{"name":"designer","content":"nueva, https://nueva.ch"},{"name":"copyright","content":"nueva, https://nueva.ch"},{"name":"msapplication-TileColor","content":"#00a5ff"},{"name":"theme-color","content":"#FFFFFF"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicon/apple-touch-icon.png"},{"rel":"icon","sizes":"32x32","href":"/favicon/favicon-32x32.png"},{"rel":"icon","sizes":"16x16","href":"/favicon/favicon-16x16.png"},{"rel":"manifest","href":"/favicon/site.webmanifest"},{"rel":"mask-icon","href":"/favicon/safari-pinned-tab.svg","color":"#00a5ff"}],"style":[],"script":[],"noscript":[],"charset":"utf-8","htmlAttrs":{"lang":"de"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false