<template>
  <div>
    <NuxtLayout class="__nuxt-layout">
      <div class="page__background" />
      <lenis
        @initiated="addLenis($event, 'global');"
      >
        <NuxtPage class="__nuxt-page" />
      </lenis>

      <BlockOverlayCookie
        v-if="campaignData?.cookies && publicRuntimeConfig.USE_GTM && publicRuntimeConfig.GTM_ID"
        :title="campaignData?.cookies.title"
        :text="campaignData?.cookies.text"
        :buttons="campaignData?.cookies.buttons"
        :links="campaignData?.cookies.links"
        :gtag-id="publicRuntimeConfig.GTM_ID"
      />
    </NuxtLayout>
  </div>
</template>

<script setup>
const { locale } = useI18n();
const { public: publicRuntimeConfig } = useRuntimeConfig();

const campaignData = ref({});
campaignData.value = getCampaignGlobals(locale.value);

watch(() => locale.value, () => {
    campaignData.value = getCampaignGlobals(locale.value);
});

provide('campaignData', campaignData);

/*
  Check if a user exists in the cookies and set the user state.
*/
setUser();
</script>

<style lang="scss">
.page__background {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--c-page--bg);
}

/*
    Page Transition
*/
// Please leave this for now, maybe we will use it later
.page-enter-from {
    // transform: scale(1.025);
}

.page-enter-active,
.page-leave-active {
    transition: opacity 0.1s, transform 0.25s;
}

.page-enter-active {
    transition-timing-function: ease-out;
}

.page-leave-to,
.page-enter-from {
    opacity: 0;
}

.__nuxt-page {
    min-height: calc(100vh - var(--header-height) - 60px);
}

.__nuxt-layout {
    overflow-x: hidden;
}
</style>
