<template>
  <div class="error-layout">
    <div class="page__background" />

    <div class="error-layout__inner">
      <div class="error-layout__image-wrapper">
        <AtomImage
          class="error-layout__image is-big"
          :image="{
            alt: 'error image',
            src: '/images/campaign-logo.png',
          }"
        />
        <AtomImage
          class="error-layout__image is-small"
          :image="{
            alt: 'error image',
            src: '/images/company-logo.png',
          }"
        />
      </div>

      <div class="error-layout__wrapper">
        <div class="error-layout__column">
          <div class="error-layout__code">
            <AtomTextHeading
              html-tag="h1"
              font-size="h1"
              :text="`${error.statusCode} ${errorTitle}`"
            />
          </div>
        </div>

        <div class="error-layout__column">
          <div class="error-layout__content">
            <AtomTextRichtext :html="error.statusCode === 404 ? messageText : error.message" />
            <p class="error-layout__message" />
          </div>
        </div>

        <AtomButton
          class="error-layout__button"
          :text="backText"
          @click="handleError"
        />
      </div>
    </div>
  </div>
</template>

<script setup>

defineProps({
    error: {
        type: Object,
        default: () => {},
    },
});

const { locale } = useI18n();
const localePath = useLocalePath();

const messageText = computed(() => {
    switch (locale.value) {
    case 'en':
        return 'Sorry, this page could not be found.';
    case 'fr-CH':
        return 'Désolé, cette page ne peut être trouvée.';
    case 'it-CH':
        return 'Spiacente, questa pagina non può essere trovata.';
    default:
        return 'Entschuldigen Sie, diese Seite kann nicht gefunden werden.';
    }
});

const errorTitle = computed(() => {
    switch (locale.value) {
    case 'en':
        return 'Error';
    case 'fr-CH':
        return 'Erreur';
    case 'it-CH':
        return 'Errore';
    default:
        return 'Fehler';
    }
});

const backText = computed(() => {
    switch (locale.value) {
    case 'en':
        return 'To the homepage';
    case 'fr-CH':
        return 'Retour à la page d\'accueil';
    case 'it-CH':
        return 'Alla pagina principale';
    default:
        return 'Zur Startseite';
    }
});

const handleError = () => {
    const redirectPath = localePath('/login');
    return clearError({ redirect: redirectPath });
};
</script>

<style lang="scss" scoped>
.error-layout {
    @include wrapper-layout;
    @include grid-layout();
}

.error-layout__inner {
    @include default-content-columns('wide');

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 60px;
}

.error-layout__image-wrapper {
    display: flex;
    column-gap: 22px;
}

.error-layout__image {
    &.is-big {
        // @include fluid('height', 170px, 216px, 350px);
        @include fluid('width', 170px, 216px, 350px);
    }

    &.is-small {
        // @include fluid('height', 69px, 83px, 140px);
        @include fluid('width', 69px, 83px, 140px);
    }
}

.error-layout__code {
    display: flex;
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--h1);
    font-weight: var(--f-weight--black);
}

.error-layout__message {
    color: var(--c-black);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
}

.error-layout__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.error-layout__button {
    align-self: flex-start;
}
</style>
