<template>
  <component
    :is="getIcon()"
    v-if="iconName"
    class="ion-icon"
  />
</template>

<script setup>
const props = defineProps({
    iconName: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },
});

const getIcon = () => defineAsyncComponent(
    () => import(`../../../assets/icons/${props.iconName}.svg?component`),
);
</script>
