/*
    This middleware is used to fetch the global settings of the campaign.
    It ensures that the settings are available on the client and server side.
*/
export default defineNuxtRouteMiddleware(async () => {
    const { data: nuxtData } = useNuxtData();
    if (process.client || nuxtData.value) return;

    /*
        Fetch campaing settings
    */
    const campaignEndpoints = {
        modules: '/api/modules',
        pages: '/api/pages',
        pagePermissions: '/api/pages/permissions',
        globals: '/api/globals',
    };

    const promises = Object.entries(campaignEndpoints).map(
        ([key, endpoint]) => useAsyncData(key, async () => {
            const { data } = await useFetch(endpoint);
            return data;
        }),
    );

    await Promise.all(promises);
});
